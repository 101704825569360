<template>
  <div>
    <div class="row">
      <div class="col-12 pl-0">
        <span class="float-right mt-2">
          <button class="btn btn-xs btn-dark font-weight-bold e-text-white" type="button" @click="redirectToAuditorList">
            <font-awesome-icon icon="chevron-left" class="e-text-black"/> Go Back
          </button>
        </span>
        <h3 class="text-left">Auditor Profile</h3>
        <div class="card p-3 shadow-sm">
          <form @submit.prevent="updateUser">
            <div class="row align-items-center">
              <div class="col-12">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <h5 class="e-text-red">
                      {{ auditorData ? auditorData.fullname : '' }}
                    </h5>
                  </div>
                  <div class="col-auto" v-if="isAllowed('audit-team.auditors.edit')">
                    <button class="e-btn e-btn-outlined-red e-text-red e-text-white-hover mr-1" type="button" v-if="toEdit" @click="cancelEdit">
                      <font-awesome-icon icon="ban" class="e-text-red"/>
                      Cancel
                    </button>
                    <button class="e-btn e-btn-outlined-orange e-text-orange e-text-white-hover" v-else type="button" @click="editAuditor">
                      <font-awesome-icon icon="pen" class="e-text-orange"/>
                      Edit Auditor
                    </button>
                    <button class="e-btn e-btn-green e-text-white ml-1" type="submit" v-if="toEdit">
                      <font-awesome-icon icon="save"/>
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 align-self-start">
                <div class="card">
                  <div class="card-body text-center p-1">
                    <ImageCapture v-if="toImgCapture" @cancel-photo="cancelPhoto" @photo-saved="handlePhotoSaved"/>
                    <template v-else>
                      <ImageUpload
                        ref="image-upload-component"
                        :visibility="false"
                        :trigger-upload-button="true"
                        :image-height="'230.5px'"
                        @cancel-photo="cancelPhoto"
                        @photo-saved="handlePhotoSaved"
                        @handle-file-change="toImgUpload = true"
                      />
                      <div v-if="!toImgUpload">
                        <div :style="{ backgroundImage: 'url(' + auditorPic + ')', minWidth: '100%', height: '230.5px' }" class="background-contain">
                        </div>
                        <div>
                          <button class="btn btn-sm btn-primary m-1"
                                  @click.prevent="toImgCapture = !toImgCapture">
                            Capture
                          </button>
                          <button class="btn btn-sm btn-success m-1"
                                  @click.prevent="$refs['image-upload-component'].clickUploadButton()">
                            Upload
                          </button>
                          <button class="btn btn-sm btn-secondary m-1"
                                  @click.prevent="resetPhoto()">
                            Reset
                          </button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-lg-10 p-lg-0">
                <div class="row align-items-center no-gutters my-1">
                  <div class="col-12">
                    <h6 class="mb-0 font-weight-bold e-text-red">Auditor's Information</h6>
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">First Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.first_name" name="first_name" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData ? auditorData.first_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Last Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.last_name" name="last_name" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData ? auditorData.last_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Middle Name:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.middle_name" name="middle_name" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData ? auditorData.middle_name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Suffix:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.suffix" name="suffix" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData ? auditorData.suffix : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Contact Number:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.contact_number" name="contact_number" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData.contact_number ?? '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Email:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input type="text" v-model="auditorEditedData.email" name="email" class="form-control form-control-sm">
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData ? auditorData.email : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Department:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="management_office_id" v-model="auditorEditedData.auditor_department_id">
                      <option :value="null">—— Select Department ——</option>
                      <option v-for="department in auditor_departments" :key="department.id" :value="department.id">
                        {{ department.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (auditorData && auditorData.department) ? auditorData.department.name : '' }}
                  </div>
                </div>

                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Position:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="management_office_id" v-model="auditorEditedData.auditor_position_id">
                      <option :value="null">—— Select Position ——</option>
                      <option v-for="position in auditor_positions" :key="position.id" :value="position.id">
                        {{ position.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (auditorData && auditorData.position) ? auditorData.position.name : '' }}
                  </div>
                </div>

                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Management Office:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="management_office_id" v-model="auditorEditedData.management_office_id" @change="changeManagementOffice(auditorEditedData.management_office_id)">
                      <option :value="null">—— Select Management Office ——</option>
                      <option v-for="mngmntOffice in mngmntOfficeKeys" :key="mngmntOffice.id" :value="mngmntOffice.id">
                        {{ mngmntOffice.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ (auditorData && auditorData.management_office) ? auditorData.management_office.name : '' }}
                  </div>
                </div>
                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">External Auditor?:</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <select class="form-control form-control-sm text-uppercase" name="is_internal_of_external_auditor" v-model="auditorEditedData.is_internal_of_external_auditor">
                      <option :value="true">Yes</option>
                      <option :value="false">No</option>
                    </select>
                  </div>
                  <div class="col-lg-10" v-else>
                    {{ auditorData?.is_internal_of_external_auditor ?? auditorData?.is_internal_of_external_auditor === true ? 'Yes' : 'No' }}
                  </div>
                </div>
<!--                <div class="row align-items-center no-gutters mb-1">-->
<!--                  <div class="col-lg-2">-->
<!--                    <label class="font-weight-bolder mb-0">Is Auditor Lead?:</label>-->
<!--                  </div>-->
<!--                  <div class="col-lg-10" v-if="toEdit">-->
<!--                    <select class="form-control form-control-sm text-uppercase" name="is_internal_of_external_auditor" v-model="auditorEditedData.is_lead">-->
<!--                      <option :value="true">Yes</option>-->
<!--                      <option :value="false">No</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                  <div class="col-lg-10" v-else>-->
<!--                    {{ auditorData?.is_lead ?? auditorData?.is_lead === true ? 'Yes' : 'No' }}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row align-items-center no-gutters mb-1">-->
<!--                  <div class="col-lg-2">-->
<!--                    <label class="font-weight-bolder mb-0">{{ auditorData?.is_lead ?? auditorData?.is_lead === true ? 'Members' : 'Lead' }}:</label>-->
<!--                  </div>-->
<!--                  <div class="col-lg-10" v-if="toEdit">-->
<!--                    <multi-select-custom-->
<!--                      v-if="auditorEditedData.is_lead"-->
<!--                      id="members-data"-->
<!--                      :items="nonLeadAuditors" :entity="'Members'"-->
<!--                      @doneSelected="setNonLeadMembers"-->
<!--                      :selected="auditorEditedData.members_ids">-->
<!--                    </multi-select-custom>-->
<!--                    <multi-select-custom-->
<!--                      v-else-->
<!--                      id="lead-data"-->
<!--                      :items="leadAuditors" :entity="'Lead'"-->
<!--                      @doneSelected="setLeadMembers"-->
<!--                      :selected="auditorEditedData.lead_ids">-->
<!--                    </multi-select-custom>-->
<!--                  </div>-->
<!--                  <div class="col-lg-10" v-else>-->
<!--                    <template v-if="auditorData?.is_lead === true">-->
<!--                      <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="auditor in auditorData.members">{{ auditor.name }}</span>-->
<!--                    </template>-->
<!--                    <template v-else>-->
<!--                      <span class="badge badge-pill badge-secondary mr-1 p-2" v-for="auditor in auditorData.lead">{{ auditor.name }}</span>-->
<!--                    </template>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="row align-items-center no-gutters mb-1">
                  <div class="col-lg-2">
                    <label class="font-weight-bolder mb-0">Auditor Certificate</label>
                  </div>
                  <div class="col-lg-10" v-if="toEdit">
                    <input v-if="auditorEditedData.certificate === null" type="file" class="form-control-sm" ref="auditor_certificate" accept="application/pdf" @change="setAuditorCertificate">
                    <div v-else class="dropdown dropright">
                      <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                         id="actionDropdown"
                                         aria-expanded="false"
                                         aria-haspopup="true"
                                         data-toggle="dropdown"
                      ></font-awesome-icon>
                      <span class="ml-2">{{ auditorEditedData?.certificate?.file_name }}</span>
                      <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                        <button class="dropdown-item text-secondary font-weight-bolder"
                                v-if="auditorData.certificate !== null"
                                @click.prevent="viewCertificate"
                        >
                          <font-awesome-icon icon="eye"/>
                          View
                        </button>
                        <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                                @click.prevent="removeCertificate"
                        >
                          <font-awesome-icon icon="trash" class="force-danger-all"/>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10" v-else>
                    <font-awesome-icon icon="file-pdf" v-if="auditorData?.certificate === null" size="3x"></font-awesome-icon>
                    <div v-else class="dropdown dropright">
                      <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                         id="actionDropdown"
                                         aria-expanded="false"
                                         aria-haspopup="true"
                                         data-toggle="dropdown"
                      >
                      </font-awesome-icon>
                      <span class="ml-2">{{ auditorData?.certificate?.file_name }}</span>
                      <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                        <button class="dropdown-item text-secondary font-weight-bolder"
                                @click.prevent="viewCertificate"
                        >
                          <font-awesome-icon icon="eye"/>
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>



                <template v-if="toEdit">
                  <div class="row align-items-center no-gutters mb-1" v-if="showCertificateField === true || auditorEditedData.certificate !== null">
                    <div class="col-lg-2">
                      <label class="font-weight-bolder mb-0">Certificate Issued Date </label>
                    </div>
                    <div class="col-lg-10">
                      <input type="date" :min="minimumDate"  :max="currentDate" class="form-control form-control-sm" v-model="auditorEditedData.certificate_issued_date">
                    </div>
                  </div>


                  <div class="row align-items-center no-gutters mb-1" v-if="showCertificateField === true || auditorEditedData.certificate !== null">
                    <div class="col-lg-2">
                      <label class="font-weight-bolder mb-0">Certificate Expiry Date</label>
                    </div>
                    <div class="col-lg-10">
                      <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="auditorEditedData.certificate_expiration_date">
                    </div>
                  </div>

                </template>
                <template v-else>
                  <div class="row align-items-center no-gutters mb-1" v-if="auditorData.certificate !== null">
                    <div class="col-lg-2">
                      <label class="font-weight-bolder mb-0">Certificate Issued Date</label>
                    </div>
                    <div class="col-lg-10">
                      {{ auditorData?.certificate !== null ? auditorData?.certificate?.issued_date : '' }}
                    </div>
                  </div>

                  <div class="row align-items-center no-gutters mb-1" v-if="auditorData.certificate !== null">
                    <div class="col-lg-2">
                      <label class="font-weight-bolder mb-0">Certificate Expiry Date</label>
                    </div>
                    <div class="col-lg-10">
                      {{ auditorData?.certificate !== null ? auditorData?.certificate.expiration_date : '' }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoading from "@/components/elements/AppLoading";
import {Form} from "form-backend-validation";
import {UserService} from "@/services/UserService";
import {mapActions, mapGetters} from "vuex";
import {AlertService} from "@/services/AlertService";
import {permissionMixin} from "@/mixins/permissionMixins";
import MultiSelectCustom from "@/components/MultiSelectCustom.vue";
import AuditorMixin from "@/mixins/AuditorMixin";
import DateMixin from "@/mixins/DateMixin";
import ImageCapture from "@/components/common/avatar/ImageCapture.vue";
import ImageUpload from "@/components/common/avatar/ImageUpload.vue";

export default {
  name: "AuditorProfile",
  mixins:[permissionMixin, DateMixin, AuditorMixin],
  data () {
    return {
      staffIcon: require('@/assets/staff_icon.png'),
      auditorPic : require('@/assets/staff_icon.png'),
      loading: false,
      auditorEditedData : null,
      toEdit: false,
      toImgUpload: false,
      toImgCapture: false,
      showCertificateField : false,
      certificateToDelete : null
    }
  },

  methods: {
    ...mapActions(['getMngmtOfficeKeys', 'getAuditorData', 'getAuditorList']),
    redirectToAuditorList() {
      return this.$router.push({name: 'AuditorList'});
    },
    cancelEdit() {
      this.toEdit = false
      this.auditorEditedData = Object.assign({}, this.auditorData);
      this.certificateToDelete = null;
      this.showCertificateField = false;
    },
    setLeadMembers(leadMemberIds) {
      this.auditorEditedData.lead_ids = leadMemberIds.selected;
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.auditorEditedData.members_ids = nonLeadMemberIds.selected;
    },
    editAuditor() {
      this.auditorEditedData = Object.assign({}, this.auditorData);
      if (this.auditorData.certificate === null) {
        this.auditorEditedData.certificate_file = null;
        this.auditorEditedData.certificate_issued_date = null;
        this.auditorEditedData.certificate_expiration_date = null;
      } else {
        this.auditorEditedData.certificate_file = this.auditorData.certificate.path;
        this.auditorEditedData.certificate_issued_date = this.auditorData.certificate.issued_date;
        this.auditorEditedData.certificate_expiration_date = this.auditorData.certificate.issued_date;
      }
      this.toEdit = true
    },
    changeManagementOffice(managementOfficeId) {
      let managementOffice = this.mngmntOfficeKeys.filter((managementOffice) => {
        return managementOffice.id === managementOfficeId;
      });
      managementOffice = managementOffice.length === 0 ? null : managementOffice[0];
      this.auditorEditedData.management_office = managementOffice;
    },
    async updateUser() {
      if (this.toEdit === true) {
        let param = Object.assign({}, this.auditorEditedData);

        if (param.first_name === null) {
          delete param.first_name;
        }

        if (param.middle_name === null) {
          delete param.middle_name;
        }

        if (param.suffix === null) {
          delete param.suffix;
        }

        if (param.contact_number === null) {
          delete param.contact_number;
        }

        if (param.email === null) {
          delete param.email;
        }

        if (param.certificate_file !== null && !(param.certificate_file instanceof File)) {
          delete param.certificate_file;
        }

        if ((this.auditorData.certificate === null && param.certificate_file === null) || param.certificate_file === null) {
          delete param.certificate_file;
          delete param.certificate_expiration_date;
          delete param.certificate_issued_date;
        }
        delete param.profile_photo;
        param.is_lead = param.is_lead === true ? 1 : 0;
        param.is_internal_of_external_auditor = param.is_internal_of_external_auditor === true ? 1 : 0;

        let formData = new FormData();
        formData = this.transformAuditorIds(param, formData);
        let updateResponse = await this.updateAuditor({
          id : this.auditorEditedData.id,
          formData : formData
        });

        let deleteCertificateResponse = {
          result : true
        }
        if (this.certificateToDelete !== null) {
          deleteCertificateResponse = await this.deleteAuditorCertificate(this.certificateToDelete.id);
        }

        if (updateResponse.result === false || deleteCertificateResponse.result === false) {
          return AlertService.errorAlert(updateResponse.message, 'UPDATING OF AUDITOR');
        }

        this.certificateToDelete = null;

        await this.getAuditorData({
          auditor_id : this.$route.params.id
        });
        this.toEdit = false;
        AlertService.successAlert(updateResponse.message, 'UPDATING OF AUDITOR');

      }
    },
    setAuditorCertificate() {
      this.auditorEditedData.certificate_issued_date = null;
      this.auditorEditedData.certificate_expiration_date = null;
      if (this.$refs.auditor_certificate.files[0]) {
        this.auditorEditedData.certificate_file = this.$refs.auditor_certificate.files[0];
        this.showCertificateField = true;
      } else {
        this.auditorEditedData.certificate_file = null;
        this.showCertificateField = false;
      }
    },
    removeCertificate() {
      if (this.auditorEditedData.certificate !== null) {
        this.certificateToDelete = this.auditorEditedData.certificate;
        this.auditorEditedData.certificate = null;
      }
      this.auditorEditedData.certificate_file = null;
      this.showCertificateField = false;
    },
    viewCertificate() {
      let auditorCert = this.toEdit === false ? this.auditorData.certificate.path_url : this.auditorEditedData.certificate.path_url;
      if (auditorCert !== null) {
        window.open(auditorCert,'_blank');
      }
    },
    cancelPhoto() {
      console.log('Photo capture cancelled.');
      this.toImgCapture = false
      this.toImgUpload = false
    },
    async convertToBlob(imageSrc) {
      return fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          return blob;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    async resetPhoto() {
      const captureImageToBlob = await this.convertToBlob(this.staffIcon);
      const newImage = new File([captureImageToBlob], 'image.png', { type: 'image/png' });
      let formData = new FormData();
      formData.append('profile_photo', newImage)
      let updateResponse = await this.updateAuditor({
        id : this.$route.params.id,
        formData : formData
      });
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'RESETTING OF AUDITOR PROFILE PIC');
      }
      this.auditorPic = this.staffIcon;
      AlertService.successAlert(updateResponse.message, 'RESETTING OF AUDITOR PROFILE PIC');
    },


    async handlePhotoSaved(data) {
      //todo save in backend then show on image container
      this.toImgCapture = false
      this.toImgUpload = false
      this.auditorPic = data.url;
      let formData = new FormData();
      formData.append('profile_photo', data.file)
      let updateResponse = await this.updateAuditor({
        id : this.$route.params.id,
        formData : formData
      });

      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF AUDITOR PROFILE PIC');
      }

      AlertService.successAlert(updateResponse.message, 'UPDATING OF AUDITOR PROFILE PIC');
    },
  },

  components: {
    ImageUpload,
    ImageCapture,
    MultiSelectCustom,
    'loading': AppLoading,
  },
  computed: {
    ...mapGetters(['mngmntOfficeKeys', 'auditorData', 'leadAuditors', 'nonLeadAuditors']),
  },
  mounted() {
    $('#main_content').css({
      marginLeft: '15px',
      marginRight: '10px'
    });
  },

  async created() {
    await this.getAuditorData({
      auditor_id : this.$route.params.id
    });
    await this.getMngmtOfficeKeys();

    if (this.auditorData.profile_photo_url !== null) {
      this.auditorPic = this.auditorData.profile_photo_url;
    }

  },
}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;
  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}
.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;
  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}
table tbody td {
  vertical-align: middle !important;
}

table tbody td label {
  margin-bottom: 0 !important;
}
#ModelNav {
  padding: 0;
  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }
  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}
.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;
  * {
    color: #d3313c;
  }
}

</style>
