<script>
import {AlertService} from "@/services/AlertService";

export default {
  name: "ImageUpload",
  props: {
    visibility : {
      type : Boolean,
      default : true
    },
    triggerUploadButton : {
      type : Boolean,
      default: false
    },
    imageHeight : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      previewUrl: null,
      selectedFile: null
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (!file.type.startsWith('image/')) {
          AlertService.errorAlert('Please select an image file.', 'UPLOADING AN IMAGE')
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          this.previewUrl = reader.result;
          this.selectedFile = file;
          this.$emit('handle-file-change');
        };
        reader.readAsDataURL(file);
      }
    },
    cancelPhoto() {
      this.previewUrl = null;
      this.$emit('cancel-photo');
    },
    reUpload() {
      if (this.triggerUploadButton === true) {
        this.clickUploadButton();
      } else {
        this.previewUrl = null;
      }
    },
    savePhotoAndEmit() {
      if (this.selectedFile) {
        this.$emit('photo-saved', {
          url  : this.previewUrl,
          file : this.selectedFile
        });
        this.previewUrl = null;
      } else {
        alert('No image selected.');
      }
    },
    clickUploadButton() {
      this.$refs['input-image-upload'].click()
    }
  },
}
</script>

<template>
  <div>
    <div v-if="previewUrl !== null" :style="{ backgroundImage: 'url(' + previewUrl + ')', minWidth: '100%', height: imageHeight }" class="background-contain">
    </div>
    <div class="text-center" v-if="previewUrl">
      <button class="btn btn-sm btn-outline-danger m-1" @click="cancelPhoto">Cancel</button>
      <button class="btn btn-sm btn-outline-success m-1" @click="reUpload">Re-upload</button>
      <button class="btn btn-sm btn-primary m-1" @click="savePhotoAndEmit">Save</button>
    </div>
    <input v-if="visibility === false"
      ref = "input-image-upload" class="w-100 p-2 border rounded display-none" type="file" accept="image/*"
      @change="handleFileChange"
    >
    <input v-else
           class="w-100 p-2 border rounded" type="file" accept="image/*"
           @change="handleFileChange"
    >
  </div>
</template>

<style scoped>
.display-none {
  display: none;
}
</style>
