<script>
export default {
  name: "ImageCapture",
  data() {
    return {
      photoData: null,
      mediaStream: null,
      capturedFile : null
    };
  },
  mounted() {
    this.setupCamera();
  },
  methods: {
    stopCamera() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => {
          track.stop();
        });
        this.$refs.video.srcObject = null;
        this.mediaStream = null;
      }
    },
    setupCamera() {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.$refs.video.srcObject = stream;
          this.mediaStream = stream;
        })
        .catch(err => {
          console.error('Error accessing camera:', err);
        });
    },
    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.photoData = canvas.toDataURL('image/png');
      let captureImageToBlob = this.base64ToBlob(this.photoData);
      this.capturedFile = new File([captureImageToBlob], 'image.png', { type: 'image/png' });
    },
    cancelPhoto() {
      this.stopCamera();
      this.$emit('cancel-photo');
    },
    retakePhoto() {
      this.photoData = null;
      this.setupCamera();
    },
    base64ToBlob(base64) {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: 'image/png' });
    },
    savePhotoAndEmit() {
      this.$emit('photo-saved', {
        url  : this.photoData,
        file : this.capturedFile
      });
    }
  }
}
</script>

<template>
  <div>
    <div class="text-center">
      <video ref="video" width="100%" height="100%" autoplay :style="{ display : photoData !== null ? 'none' : ''}"></video>
      <template v-if="photoData == null">
        <button class="btn btn-sm btn-outline-danger m-1" @click="cancelPhoto">Cancel</button>
        <button @click="takePhoto" class="btn btn-sm btn-primary">Take Photo</button>
      </template>
    </div>
    <div class="text-center" v-if="photoData !== null">
      <img :src="photoData" alt="Captured Photo">
      <button @click="cancelPhoto" class="btn btn-sm btn-outline-danger m-1">Cancel</button>
      <button @click="retakePhoto" class="btn btn-sm btn-outline-success m-1">Retake</button>
      <button @click="savePhotoAndEmit" class="btn btn-sm btn-primary m-1">Save</button>
    </div>
    <canvas ref="canvas" width="400" height="300" style="display: none;"></canvas>
  </div>
</template>

<style scoped>

</style>
